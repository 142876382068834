<script>
import PageHeader from '@/components/page-header'
import {
	DxDataGrid,
	DxColumn,
	DxFilterRow,
	DxFilterPanel,
	DxGrouping,
	DxGroupPanel,
	DxHeaderFilter,
	DxPager,
	DxPaging,
	DxSearchPanel,
	DxExport,
	DxEditing,
	DxRequiredRule,
	DxLookup
} from 'devextreme-vue/data-grid'
import { config } from '@/helpers/config'
import { http } from '@/helpers/http'
import { alert } from '@/helpers/alert'
import {userService} from "@/helpers/authservice/user.service";

export default {
	name: 'CategoryTypes',
	props: {
		category: { type: Object, required: true },
		brands: { type: Array, required: true },
	},
	components: {
		PageHeader,
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxFilterPanel,
		DxGrouping,
		DxGroupPanel,
		DxHeaderFilter,
		DxPager,
		DxPaging,
		DxSearchPanel,
		DxExport,
		DxEditing,
		DxRequiredRule,
		DxLookup,
	},
	data() {
		return {
			types: [],
			editorOptions: { showClearButton: true },
			config
		};
	},
	created() {
		this.types = this.category.types
	},
	destroyed() { },
	computed: {
		currentDate() {
			return new Date().toISOString().slice(0, 10)
		}
	},
	methods: {
		async getCategoryTypes(categoryId) {
			let response
			try {
				response = await http.get(`settings/category/${categoryId}`).json()
			} catch (e) {
				alert.error(e.message)
				return
			}
			this.types = response.types
		},
		addCategoryType(row) {
			row.cancel = this.addCategoryTypeRequest(row.data)
		},
		async addCategoryTypeRequest(data) {
			try {
				await http.post('settings/type/save', {
					json: {
						categoryId: this.category.id,
						name: data.name,
						priority: data.priority,
						visible: data.visible,
						brandId: data.brandId,
					},
				})
			} catch (e) {
				alert.error(e.message)
				return true
			}
			alert.success('Category type successfully added!')
			this.getCategoryTypes(this.category.id)
			userService.getTicketCategoryList()
		},
		updateCategoryType(row) {
			const data = { ...row.oldData, ...row.newData }
			row.cancel = this.updateCategoryTypeRequest(data)
		},
		async updateCategoryTypeRequest(data) {
			try {
				await http.post('settings/type/save', {
					json: {
						id: data.id,
						categoryId: this.category.id,
						name: data.name,
						priority: data.priority,
						visible: data.visible,
						brandId: data.brandId,
					},
				})
			} catch (e) {
				alert.error(e.message)
				return true
			}
			alert.success('Category type successfully updated!')
			this.getCategoryTypes(this.category.id)
		},
	}
}
</script>

<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<PageHeader :title="category.name" />
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<DxDataGrid
					:data-source="types"
					:remote-operations="false"
					:allow-column-reordering="true"
					:row-alternation-enabled="true"
					:hover-state-enabled="false"
					:show-borders="false"
					:column-auto-width="true"
					:word-wrap-enabled="true"
					selection-mode="single"
					:on-row-inserting="addCategoryType"
					:on-row-updating="updateCategoryType"
				>

					<DxColumn
						data-field="id"
						caption="Id"
						alignment="left"
						:allow-editing="false"
						width="80"
					/>
					<DxColumn data-field="name" caption="Type">
						<DxRequiredRule />
					</DxColumn>
					<DxColumn
						data-field="brandId"
						caption="Brand"
						:editor-options="editorOptions">
						<DxLookup
							:data-source="brands"
							display-expr="name"
							value-expr="id" />
					</DxColumn>
					<DxColumn data-field="priority" width="120">
						<DxRequiredRule />
					</DxColumn>
					<DxColumn
						data-field="visible"
						data-type="boolean"
						true-text="Yes"
						false-text="No"
						width="120"
						css-class="visible-column"
					/>
					<DxExport
						:enabled="true"
						:file-name="`${currentDate}-${this.$route.name}`"
					/>
					<DxEditing :allow-updating="true" :allow-adding="true" mode="row" />
					<DxHeaderFilter :visible="true" />
				</DxDataGrid>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.dx-header-row .visible-column {
	.dx-sort-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
		max-width: calc(100% - 30px);
	}
	.dx-visibility-hidden {
		display: none;
	}
}
</style>
