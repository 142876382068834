<script>
import PageHeader from '@/components/page-header'
import {
	DxDataGrid,
	DxColumn,
	DxFilterRow,
	DxFilterPanel,
	DxGrouping,
	DxGroupPanel,
	DxHeaderFilter,
	DxPager,
	DxPaging,
	DxSearchPanel,
	DxExport,
	DxEditing,
	DxRequiredRule,
	DxLookup,
	DxMasterDetail
} from 'devextreme-vue/data-grid'
import { config } from '@/helpers/config'
import { http } from '@/helpers/http'
import { alert } from '@/helpers/alert'

import CategoryTypes from './components/category-types'
import Switches from 'vue-switches';
import {userService} from "@/helpers/authservice/user.service";

export default {
	name: 'RequestTypes',
	props: { },
	components: {
		PageHeader,
		Switches,
		CategoryTypes,
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxFilterPanel,
		DxGrouping,
		DxGroupPanel,
		DxHeaderFilter,
		DxPager,
		DxPaging,
		DxSearchPanel,
		DxExport,
		DxEditing,
		DxRequiredRule,
		DxLookup,
		DxMasterDetail,
	},
	data() {
		return {
			title: 'Request types',
			editorOptions: { showClearButton: true },
			categories: [],
			enabled: false,
			config
		};
	},
	created() {
		this.enabled = this.isCategoryEnabled
		this.getCategories()
	},
	computed: {
		currentDate() {
			return new Date().toISOString().slice(0, 10)
		},
		brands() {
			return JSON.parse(localStorage.getItem(config.storage.BRANDS))
		},
		isCategoryEnabled() {
			return JSON.parse(localStorage.getItem(config.storage.CATEGORIES_ENABLED)) ?? false
		},
	},
	methods: {
		async getCategoryStatus() {
			let response
			try {
				response = await http.get('settings/category/enabled').json()
			} catch (e) {
				// Show error
				return
			}
			this.enabled = response
		},
		async changeCategoryStatus(enabled) {
			try {
				let response = await http.post('settings/category/enabled', {
					json: {
						enabled: enabled
					}
				}).json()
				this.enabled = response
				localStorage.setItem(config.storage.CATEGORIES_ENABLED, response)
			} catch (e) {}
		},
		async getCategories() {
			let response
			try {
				response = await http.get('settings/category/all').json()
			} catch (e) {
				alert.error(e.message)
				return
			}
			this.categories = response
		},
		addCategory(row) {
			row.cancel = this.addCategoryRequest(row.data)
		},
		async addCategoryRequest(data) {
			try {
				await http.post('settings/category/save', {
					json: {
						name: data.name,
						priority: data.priority,
						visible: data.visible,
						brandId: data.brandId,
					},
				})
			} catch (e) {
				alert.error(e.message)
				return true
			}
			alert.success('Category successfully added!')
			this.getCategories()
			userService.getTicketCategoryList()
		},
		updateCategory(row) {
			const data = { ...row.oldData, ...row.newData }
			row.cancel = this.updateCategoryRequest(data)
		},
		async updateCategoryRequest(data) {
			try {
				await http.post('settings/category/save', {
					json: {
						id: data.id,
						name: data.name,
						priority: data.priority,
						visible: data.visible,
						brandId: data.brandId,
					},
				})
			} catch (e) {
				alert.error(e.message)
				return true
			}
			alert.success('Category successfully updated!')
			this.getCategories()
		},
	}
}
</script>

<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<PageHeader :title="title" />
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Enable / disable requst types</h4>
						<p class="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
						<switches v-model="enabled" type-bold="true" :color="enabled ? 'success': 'default'" :emit-on-mount="false" class="mb-0" @input="changeCategoryStatus"></switches>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<DxDataGrid
					:data-source="categories"
					:remote-operations="false"
					:allow-column-reordering="true"
					:row-alternation-enabled="true"
					:hover-state-enabled="false"
					:show-borders="false"
					:column-auto-width="true"
					:word-wrap-enabled="true"
					selection-mode="single"
					:on-row-inserting="addCategory"
					:on-row-updating="updateCategory"
				>
					<DxColumn
						data-field="id"
						caption="Id"
						alignment="left"
						:allow-editing="false"
						width="80"
					/>
					<DxColumn data-field="name" caption="Category">
						<DxRequiredRule />
					</DxColumn>
					<DxColumn
						data-field="types.length"
						caption="Types"
						alignment="left"
						:allow-editing="false"
						width="100"
					/>
					<DxColumn
						data-field="brandId"
						caption="Brand"
						:editor-options="editorOptions">
						<DxLookup
							:data-source="brands"
							display-expr="name"
							value-expr="id" />
					</DxColumn>
					<DxColumn data-field="priority" width="120">
						<DxRequiredRule />
					</DxColumn>
					<DxColumn
						data-field="visible"
						data-type="boolean"
						true-text="Yes"
						false-text="No"
						width="120"
						css-class="visible-column"
					/>
					<DxExport
						:enabled="true"
						:file-name="`${currentDate}-${this.$route.name}`"
					/>
					<DxEditing :allow-updating="true" :allow-adding="true" mode="row" />
					<DxFilterRow :visible="false" />
					<DxHeaderFilter :visible="true" />
					<DxMasterDetail	:enabled="true" template="masterDetailTemplate" />
					<template #masterDetailTemplate="{ data: category }">
						<CategoryTypes :category="category.data" :brands="brands" />
					</template>
				</DxDataGrid>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.dx-header-row .visible-column {
	.dx-sort-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
		max-width: calc(100% - 30px);
	}
	.dx-visibility-hidden {
		display: none;
	}
}
</style>
